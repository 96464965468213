import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'
import snackbar from '@/plugins/snackbar'
import axios from 'axios'

export default {
	namespaced: true,
	state: {
		data: [],
		storageKey: `${site.storageKeyPrefix}::StoreOverview`,
		endpoint: endpoints.getStoreOverview,
		storeEndpoint: endpoints.storeViewGetOverview,
		dataFetched: false
	},
	getters: {
		groupedByStore: state => {
			const result = state.data.reduce((k, v) => {
				if (v.Complete) {
					return k
				}

				k[v.Store] = k[v.Store] || []
				k[v.Store].Store = `#${v.Store}`
				k[v.Store].CleanStoreNumber = v.Store

				if (Object.hasOwnProperty.call(k[v.Store], 'Winners')) {
					k[v.Store].Winners += v.Winners
				} else {
					k[v.Store].Winners = v.Winners
				}

				if (Object.hasOwnProperty.call(k[v.Store], 'Notified')) {
					k[v.Store].Notified = k[v.Store].Notified === false ? false : v.Notified === false ? false : v.Notified
				} else {
					k[v.Store].Notified = v.Notified
				}

				return k
			}, Object.create(null))

			return Object.keys(result).map(key => {
				return result[key]
			})
		},
		getData: state => {
			return state.data
		},
		count: (state, getters) => {
			return getters.groupedByStore.length
		}
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
			state.dataFetched = true
		},
		addData(state, payload) {
			state.data = [...state.data, ...payload]
		},
		updateNotified(state, index) {
			state.data[index].Notified = true
		},
		updateComplete(state, index) {
			state.data[index].Complete = true
		}
	},
	actions: {
		pullStoreOverview({ getters, commit, dispatch }, formId) {
			const formData = new FormData()
			formData.append('formId', formId)

			try {
				axios
					.post(endpoints.getStoreOverview, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(res => {
						const overviewData = getters['getData'].filter(x => x.FormId !== formId)
						commit('setData', overviewData)
						commit('addData', JSON.parse(res.data))
						dispatch('dataHandler/updateStoreOverview', formId, { root: true })
						dispatch('updateCache')
					})
					.catch(err => snackbar.error(err.response ? err.response.data : err.message, 6000))
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		markAsNotified({ state, commit, dispatch, rootGetters }, payload) {
			try {
				const data = JSON.parse(payload)
				const index = state.data.findIndex(x => ~~x.Store === ~~data.Store && x.FormId === data.FormId)

				if (rootGetters['user/isAdmin'] || rootGetters['user/isManagement']) {
					commit('updateNotified', index)
					dispatch('updateCache')
				}

				dispatch('dataHandler/updateStoreWinnersNotified', ~~data.Store, { root: true })
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		markAsQueueComplete({ state, commit, dispatch, rootGetters }, payload) {
			try {
				const data = JSON.parse(payload)
				const index = state.data.findIndex(x => ~~x.Store === ~~data.Store && x.FormId === data.FormId)

				if (rootGetters['user/isAdmin'] || rootGetters['user/isManagement']) {
					commit('updateComplete', index)
					dispatch('updateCache')
				}

				dispatch('dataHandler/updateStoreQueueMarkedComplete', ~~data.Store, { root: true })
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		updateCache({ dispatch }) {
			dispatch('setCache', 'storesOverview', { root: true })
		}
	}
}
