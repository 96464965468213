import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'
import axios from 'axios'
import snackbar from '@/plugins/snackbar'
import router from '@/router'

const dateTimeFormat = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit'
}

export default {
	namespaced: true,
	state: {
		data: [],
		storageKey: `${site.storageKeyPrefix}::RaffleEvents`,
		endpoint: endpoints.getRaffleEvents,
		storeEndpoint: endpoints.storeViewGetRaffleEvents,
		dataFetched: false
	},
	getters: {
		count: state => state.data.length,
		isDataFetched: state => state.dataFetched
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
			state.dataFetched = true
		},
		addData(state, payload) {
			state.data.unshift(payload)
		},
		setSubmissionByFormId(state, payload) {
			const index = state.data.findIndex(x => x.FormId === payload.FormId)

			if (state.data[index]) {
				const newValue = state.data[index].TotalSubmissions + payload.Data

				if (state.data[index].TotalSubmissions) {
					state.data[index].TotalSubmissions = newValue
					state.data[index].FormattedTotalSubmissions = new Intl.NumberFormat().format(newValue)
				} else {
					state.data[index].TotalSubmissions = payload.Data
					state.data[index].FormattedTotalSubmissions = new Intl.NumberFormat().format(payload.Data)
				}

				state.data[index].LastSubmissionPull = payload.LastSubmissionPull
				state.data[index].FormattedLastSubmissionPull = new Date(payload.LastSubmissionPull).toLocaleString('en-us', dateTimeFormat)
			}
		},
		setValidationByFormId(state, payload) {
			const index = state.data.findIndex(x => x.FormId === payload.FormId)

			if (state.data[index]) {
				state.data[index].ValidSubmissions = payload.ValidSubmissions
				state.data[index].DisqualifiedSubmissions = payload.DisqualifiedSubmissions
				state.data[index].ValidSubmissionsPercentage = payload.ValidSubmissionsPercentage
				state.data[index].DisqualifiedSubmissionsPercentage = payload.DisqualifiedSubmissionsPercentage
				state.data[index].LastValidationRun = payload.LastValidationRun
				state.data[index].FormattedValidSubmissions = new Intl.NumberFormat().format(payload.ValidSubmissions)
				state.data[index].FormattedDisqualifiedSubmissions = new Intl.NumberFormat().format(payload.DisqualifiedSubmissions)
				state.data[index].FormattedLastValidationRun = new Date(payload.LastValidationRun).toLocaleString('en-us', dateTimeFormat)
			}
		}
	},
	actions: {
		addEvent({ commit, dispatch }, formId) {
			const formData = new FormData()
			formData.append('formId', formId)

			try {
				axios
					.post(endpoints.getRaffleEvents, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(res => {
						const jsonData = JSON.parse(res.data)
						commit('addData', jsonData[0])
						dispatch('updateCache')
					})
					.catch(err => snackbar.error(err.response ? err.response.data : err.message, 6000))
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		editEvent({ state, commit, dispatch }, formId) {
			const formData = new FormData()
			formData.append('formId', formId)

			try {
				axios
					.post(endpoints.getRaffleEvents, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(res => {
						const jsonData = JSON.parse(res.data)
						const oldData = state.data.findIndex(x => x.FormId === formId)
						const newData = state.data
						newData[oldData] = jsonData[0]
						commit('setData', newData)
						dispatch('updateCache')
					})
					.catch(err => snackbar.error(err.response ? err.response.data : err.message, 6000))
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		async removeEvent({ state, commit, dispatch }, formId) {
			const newData = state.data.filter(x => x.FormId !== formId)

			await router.push('/')

			commit('setData', newData)
			dispatch('updateCache')
		},
		updateSubmissions({ commit, dispatch }, payload) {
			try {
				const data = JSON.parse(payload)
				commit('setSubmissionByFormId', data)
				dispatch('updateCache')
			} catch {
				snackbar.error('Failed to update submission value to app')
			}
		},
		updateValidation({ commit, dispatch }, payload) {
			try {
				const data = JSON.parse(payload)
				commit('setValidationByFormId', data)
				dispatch('updateCache')
			} catch {
				snackbar.error('Failed to update validation value to app')
			}
		},
		updateCache({ dispatch }) {
			dispatch('setCache', 'raffleEvents', { root: true })
		}
	}
}
