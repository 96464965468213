import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'

export default {
	namespaced: true,
	state: {
		data: [],
		storageKey: `${site.storageKeyPrefix}::Stores`,
		endpoint: endpoints.getStoreList,
		dataFetched: false
	},
	getters: {
		storeNumbers: state => {
			return state.data
				.flatMap(x => {
					if (Object.hasOwnProperty.call(x, 'options')) {
						return x.options.map(y => {
							if (Object.hasOwnProperty.call(y, 'value')) {
								return ~~y.value
							}
						})
					}
				})
				.sort((a, b) => a - b)
		},
		paddedStoreNumbers: state => {
			return state.data
				.flatMap(x => {
					if (Object.hasOwnProperty.call(x, 'options')) {
						return x.options.map(y => {
							if (Object.hasOwnProperty.call(y, 'value')) {
								return y.value
							}
						})
					}
				})
				.sort()
		}
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
			state.dataFetched = true
		}
	},
	actions: {}
}
