import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'

export default {
	namespaced: true,
	state: {
		data: [],
		storageKey: `${site.storageKeyPrefix}::Winners`,
		endpoint: endpoints.getWinners,
		storeEndpoint: endpoints.storeViewGetWinners,
		dataFetched: false
	},
	getters: {
		allWinners: state => {
			const dateTimeFormat = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}
			const trueValue = 'Yes'
			const falseValue = 'No'

			return state.data.map(x => {
				const address = x.details[0].Address1.trim() + (x.details[0].Address2 ? `. ${x.details[0].Address2.trim()}` : '')
				x.FullName = `${x.details[0].FirstName.trim()} ${x.details[0].LastName.trim()}`
				x.Email = x.details[0].Email
				x.Phone = x.details[0].Phone
				x.FullAddress = `${address}. ${x.details[0].City.trim()},
					${x.details[0].State.trim()}. ${x.details[0].ZipCode.trim()}`
				x.Size = x.details[0].Size
				x.EventName = x.details[0].FormTitle.trim()
				x.Store ||= 'Ecom'
				x.FormattedNotifiedOn = x.NotifiedOn ? new Date(x.NotifiedOn).toLocaleString('en-us', dateTimeFormat) : 'N/A'
				x.FormattedInvoicedOn = x.InvoicedOn ? new Date(x.InvoicedOn).toLocaleString('en-us', dateTimeFormat) : 'N/A'
				x.FormattedPickedUpInvoiced = x.Online
					? typeof x.InvoicedOn !== 'undefined'
						? trueValue
						: falseValue
					: typeof x.PickedUpOn !== 'undefined'
					? trueValue
					: falseValue
				x.PickedUpInvoiced = x.Online ? typeof x.InvoicedOn !== 'undefined' : typeof x.PickedUpOn !== 'undefined'
				x.StoreNotificationMethod = x.EmailNotificationId ? 'Email' : x.SmsNotificationId ? 'SMS' : false
				x.CustomerNotifiedText = x.CustomerNotified ? trueValue : falseValue

				if (!isNaN(x.ShippingCharge)) {
					x.ShippingCharge = x.ShippingCharge
						? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.ShippingCharge)
						: '$0.00'
				}

				return x
			})
		},
		pendingPickup: (state, getters, rootState) => {
			return state.data.filter(x => {
				if (!x.PickedUp && !x.PickedUpOn && !x.Online) {
					const storeOverview = rootState.storesOverview.data.find(n => n.FormId === x.FormId && n.Store === x.Store)
					if (storeOverview && !storeOverview.Complete && storeOverview.Notified) {
						x.StoreNumber = `#${x.Store}` || '#-1'
						x.CleanStoreNumber = x.Store || -1
						x.FullName =
							x.details[0].FirstName && x.details[0].LastName
								? `${x.details[0].FirstName} ${x.details[0].LastName}`
								: 'No Name Registered'
						x.Size = x.details[0].Size || -1
						x.EventName = x.details[0].FormTitle || 'No Title'
						return x
					}
				}
			})
		},
		pendingPickupCount: (state, getters) => {
			return getters.pendingPickup.length
		},
		storeQueue: state => state.data.filter(x => !x.PickedUp),
		rawData: state => {
			return state.data
		},
		isDataFetched: state => state.dataFetched
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
			state.dataFetched = true
		},
		addData(state, payload) {
			state.data = [...state.data, ...payload]
		},
		updatePickedUp(state, index) {
			state.data[index].PickedUp = true
			state.data[index].PickedUpOn = new Date()
		}
	},
	actions: {
		pullWinners({ dispatch, rootGetters }, formId) {
			if (rootGetters['user/isAdmin'] || rootGetters['user/isManagement']) {
				dispatch('storesOverview/pullStoreOverview', formId, { root: true })
			}

			dispatch('dataHandler/updateNewStoreWinners', null, { root: true })
		},
		removeWinner({ state, commit, dispatch, rootGetters }, payload) {
			const condition = JSON.parse(payload)

			const newData = state.data.filter(x => {
				return x.SubmissionId !== condition.SubmissionId || x.FormId !== condition.FormId
			})

			if (rootGetters['user/isAdmin'] || rootGetters['user/isManagement']) {
				commit('setData', newData)
			}

			dispatch('dataHandler/updateStoreWinnerRemoval', ~~condition.Store, { root: true })
		},
		markAsPickedUp({ dispatch }, store) {
			dispatch('dataHandler/updateStoreWinnersPendingPickup', store, { root: true })
		}
	}
}
