import snackbar from '@/plugins/snackbar'

const defaultValue = { update: true, data: false }

export default {
	namespaced: true,
	state: {
		data: {}
	},
	getters: {
		storeWinnersPickedUpTodayCount: state => state.data['storeWinnersPickedUpTodayCount'] || defaultValue,
		storeWinnersPendingPickupCount: state => state.data['storeWinnersPendingPickupCount'] || defaultValue,
		storeWinnersNotifiedTodayCount: state => state.data['storeWinnersNotifiedTodayCount'] || defaultValue,
		storeWinnersPendingNotificationCount: state => state.data['storeWinnersPendingNotificationCount'] || defaultValue,
		storeEventsMarkedAsCompleteTodayCount: state => state.data['storeEventsMarkedAsCompleteTodayCount'] || defaultValue,
		storeWinnersPendingPickupDataArray: state => state.data['storeWinnersPendingPickupDataArray'] || defaultValue,
		storeEventsPendingPickupDataArray: state => state.data['storeEventsPendingPickupDataArray'] || defaultValue,
		storeQueueOverviewByEventDataArray: state => state.data['storeQueueOverviewByEventDataArray'] || defaultValue,
		storeWinnersRemovedLogDataArray: state => state.data['storeWinnersRemovedLogDataArray'] || defaultValue,
		allStoreWinnersByEventDataArray: state => state.data['allStoreWinnersByEventDataArray'] || defaultValue,
		storeQueueEventsPendingNotificationDataArray: state => state.data['storeQueueEventsPendingNotificationDataArray'] || defaultValue,
		storeQueuePendingNotificationDataArray: state => state.data['storeQueuePendingNotificationDataArray'] || defaultValue,
		storeQueueNoWinnersDataArray: state => state.data['storeQueueNoWinnersDataArray'] || defaultValue,
		storeQueueEventsPendingPickupDataArray: state => state.data['storeQueueEventsPendingPickupDataArray'] || defaultValue,
		storeQueuePendingPickupDataArray: state => state.data['storeQueuePendingPickupDataArray'] || defaultValue,
		storeQueueUpcomingEventDataArray: state => state.data['storeQueueUpcomingEventDataArray'] || defaultValue
	},
	mutations: {
		addData(state, payload) {
			if (typeof payload === 'object') {
				if (payload.data && payload.key && payload.value) {
					state.data[payload.data] ??= {}
					try {
						state.data[payload.data][payload.key] = { update: false, data: JSON.parse(payload.value) }
					} catch {
						state.data[payload.data][payload.key] = { update: false, data: payload.value }
					}
				} else if (payload.data && payload.value) {
					try {
						state.data[payload.data] = { update: false, data: JSON.parse(payload.value) }
					} catch {
						state.data[payload.data] = { update: false, data: payload.value }
					}
				}
			}
		},
		tagDataForUpdate(state, payload) {
			if (typeof payload === 'object') {
				try {
					if (payload.data) {
						if (!state.data[payload.data]) {
							if (payload.key) {
								state.data[payload.data] = {}
								state.data[payload.data][payload.key] = defaultValue
							} else {
								state.data[payload.data] = defaultValue
							}
						} else {
							if (payload.key) {
								if (state.data[payload.data][payload.key]) {
									state.data[payload.data][payload.key]['update'] = true
								} else {
									state.data[payload.data][payload.key] = defaultValue
								}
							} else {
								state.data[payload.data]['update'] = true
							}
						}
					}
				} catch (e) {
					snackbar.error('Error in tagging data for update')
				}
			}
		},
		tagAllPropertiesForUpdate(state, queryName) {
			if (typeof queryName === 'string') {
				state.data[queryName] ??= {}
				for (let key of Object.keys(state.data[queryName])) {
					state.data[queryName][key].update = true
				}
			}
		}
	},
	actions: {
		async fetchData({ commit, rootState }, payload) {
			const parameters = payload.parameters ? JSON.stringify(payload.parameters) : ''

			try {
				await rootState.socketHandler.connection.invoke('ApiDataHandler', payload.data, parameters).then(res => {
					commit('addData', { data: payload.data, key: payload.key || false, value: res })
				})
			} catch (e) {
				//TODO: Custom logger method in server to call when this error happens
				console.log('Uh oh! Error at socket invoke', e)
				commit('addData', { data: payload.data, key: payload.key || false, value: defaultValue })
			}
		},
		updateNewStoreWinners({ commit }) {
			commit('tagDataForUpdate', { data: 'storeWinnersPendingNotificationCount' })
			commit('tagAllPropertiesForUpdate', 'storeQueueEventsPendingNotificationDataArray')
			commit('tagAllPropertiesForUpdate', 'storeQueuePendingNotificationDataArray')
			commit('tagAllPropertiesForUpdate', 'storeQueueNoWinnersDataArray')
		},
		updateStoreWinnersPendingPickup({ commit }, store) {
			commit('tagDataForUpdate', { data: 'storeQueuePendingPickupDataArray', key: store })
			commit('tagDataForUpdate', { data: 'storeQueueEventsPendingPickupDataArray', key: store })
			commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupCount' })
			commit('tagDataForUpdate', { data: 'storeWinnersPickedUpTodayCount' })
			commit('tagDataForUpdate', { data: 'storeEventsPendingPickupDataArray' })
			commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupDataArray' })
		},
		updateStoreOverview({ commit }) {
			commit('tagDataForUpdate', { data: 'storeWinnersNotifiedTodayCount' })
			commit('tagDataForUpdate', { data: 'storeWinnersPendingNotificationCount' })
			commit('tagDataForUpdate', { data: 'storeEventsMarkedAsCompleteTodayCount' })
		},
		updateStoreWinnersNotified({ commit }, store) {
			if (store) {
				commit('tagDataForUpdate', { data: 'storeQueueEventsPendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueuePendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueueEventsPendingPickupDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueuePendingPickupDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupCount' })
				commit('tagDataForUpdate', { data: 'storeEventsPendingPickupDataArray' })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupDataArray' })
				commit('tagDataForUpdate', { data: 'storeWinnersNotifiedTodayCount' })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingNotificationCount' })
			}
		},
		updateStoreQueueMarkedComplete({ commit }, store) {
			if (store) {
				commit('tagDataForUpdate', { data: 'storeQueueEventsPendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueuePendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueueEventsPendingPickupDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueuePendingPickupDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupCount' })
				commit('tagDataForUpdate', { data: 'storeEventsPendingPickupDataArray' })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingPickupDataArray' })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingNotificationCount' })
				commit('tagDataForUpdate', { data: 'storeEventsMarkedAsCompleteTodayCount' })
			}
		},
		updateStoreWinnerRemoval({ commit }, store) {
			if (store) {
				commit('tagDataForUpdate', { data: 'storeQueuePendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueueEventsPendingNotificationDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeWinnersRemovedLogDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeQueueOverviewByEventDataArray', key: store })
				commit('tagDataForUpdate', { data: 'storeWinnersPendingNotificationCount' })
			}
		},
		bulkUpdateStoreRaffle({ dispatch }) {
			dispatch('updateStoreWinnersPendingPickup')
			dispatch('updateStoreOverview')
		}
	}
}
