<template>
	<transition name="slow-fade" mode="out-in">
		<template v-if="$store.state.ready">
			<suspense>
				<template #default>
					<div>
						<dream-console v-if="showDreamConsole" />
						<page-header />
						<router-view v-slot="{ Component }">
							<keep-alive>
								<transition name="slide" mode="out-in">
									<component :is="Component" />
								</transition>
							</keep-alive>
						</router-view>
						<teleport to="body">
							<v-snackbar bottom right dense :duration="4000" key="snackbar-message"></v-snackbar>
						</teleport>
					</div>
				</template>
			</suspense>
		</template>
		<template v-else>
			<div class="mx-auto w-100 my-5 text-center">
				<div class="display-4 text-center w-100 my-5 py-5 text-darkest-grey">CUSTOMER RAFFLE MANAGEMENT</div>
			</div>
		</template>
	</transition>
</template>

<script async setup>
import { computed, onBeforeMount, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import PageHeader from '@/views/PageHeader/Header'
import { Vue3Snackbar as VSnackbar } from 'vue3-snackbar'
import user from '@/plugins/user'

const DreamConsole = defineAsyncComponent(() => import('@/components/DreamConsole/Home'))

const store = useStore()
const maintenance = computed(() => store.state.maintenance)
const maintenanceAccess = computed(() => store.getters['maintenanceAccess'])
const showDreamConsole = computed(() => user.is(['admin']) && (maintenance.value ? maintenanceAccess.value : true))

store.dispatch('user/init')
store.dispatch('init', false)

onBeforeMount(async () => {
	await store.dispatch('taskRunner/autoFullUpdateTask')
	await store.dispatch('updateNow')
	setInterval(() => {
		store.dispatch('updateNow')
	}, 1000)
})
</script>

<style lang="scss">
@import 'src/scss/main.scss';
</style>
