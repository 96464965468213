import { site } from '@/config/app.config'

export default {
	namespaced: true,
	state: {
		isOpen: false,
		isMinimized: false,
		autoOpenLocalEvents: false,
		autoOpenGlobalEvents: false,
		clearLocalLogOnNewEvent: false,
		clearGlobalLogOnNewEvent: false,
		bodyXPosition: 74,
		bodyYPosition: 310,
		calculatedXPosition: 0,
		calculatedYPosition: 0,
		bodyWidth: 350,
		bodyHeight: 210,
		fontSizeIndex: 1,
		fontSizes: ['0.6rem', '0.8rem', '0.95rem', '1.0rem', '1.1rem', '1.4rem', '1.7rem', '1.9rem', '2.4rem', '2.9rem', '3.5rem'],
		localEvents: {},
		globalEvents: {}
	},
	getters: {
		getOpenState: state => state.isOpen,
		getMinimizedState: state => state.isMinimized,
		getAutoOpenLocalEvents: state => state.autoOpenLocalEvents,
		getAutoOpenGlobalEvents: state => state.autoOpenGlobalEvents,
		getClearLocalLogOnNewEvent: state => state.clearLocalLogOnNewEvent,
		getClearGlobalLogOnNewEvent: state => state.clearGlobalLogOnNewEvent,
		getLocalEventsData: state => state.localEvents,
		getGlobalEventsData: state => state.globalEvents,
		getBodyXPosition: state => state.bodyXPosition,
		getBodyYPosition: state => state.bodyYPosition,
		getBodyWidth: state => state.bodyWidth,
		getBodyHeight: state => state.bodyHeight,
		getFontSizes: state => state.fontSizes,
		getFontSizeIndex: state => state.fontSizeIndex
	},
	mutations: {
		setIsOpen(state, status) {
			state.isOpen = status
		},
		setIsMinimize(state, status) {
			state.isMinimized = status
		},
		mutateAutoOpenLocalEvents(state, status) {
			state.autoOpenLocalEvents = status
		},
		mutateAutoOpenGlobalEvents(state, status) {
			state.autoOpenGlobalEvents = status
		},
		mutateClearLocalLogOnNewEvent(state, status) {
			state.clearLocalLogOnNewEvent = status
		},
		mutateClearGlobalLogOnNewEvent(state, status) {
			state.clearGlobalLogOnNewEvent = status
		},
		updateBodyXPosition(state, x) {
			state.bodyXPosition = parseInt(x)
		},
		updateBodyYPosition(state, y) {
			state.bodyYPosition = parseInt(y)
		},
		updateBodyWidth(state, height) {
			state.bodyWidth = parseInt(height)
		},
		updateBodyHeight(state, height) {
			state.bodyHeight = parseInt(height)
		},
		updateFontSizeIndex(state, index) {
			state.fontSizeIndex = parseInt(index)
		},
		setData(state, payload) {
			state.data = payload
		},
		setLocalEvents(state, payload) {
			state.localEvents = payload
		},
		setGlobalEvents(state, payload) {
			state.globalEvents = payload
		},
		clearData(state, property) {
			state[property] = {}
		},
		addLocalEvent(state, payload) {
			state.localEvents[payload.key] = state.localEvents[payload.key] || {
				header: payload.header,
				executionTime: payload.executionTime
			}
			state.localEvents[payload.key] = {
				...state.localEvents[payload.key],
				...payload.data
			}
		},
		addGlobalEvent(state, payload) {
			state.globalEvents[payload.key] = state.globalEvents[payload.key] || {
				header: payload.header,
				executionTime: payload.executionTime,
				by: payload.by
			}
			state.globalEvents[payload.key] = {
				...state.globalEvents[payload.key],
				...payload.data
			}
		}
	},
	actions: {
		init({ dispatch }) {
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowState',
					commit: 'logWindow/setIsOpen',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowMinimize',
					commit: 'logWindow/setIsMinimize',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowFontSizeIndex',
					commit: 'logWindow/updateFontSizeIndex',
					storage: 'local'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowBodyXPosition',
					commit: 'logWindow/updateBodyXPosition',
					storage: 'local'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowBodyYPosition',
					commit: 'logWindow/updateBodyYPosition',
					storage: 'local'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowBodyWidth',
					commit: 'logWindow/updateBodyWidth',
					storage: 'local'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::LogWindowBodyHeight',
					commit: 'logWindow/updateBodyHeight',
					storage: 'local'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::AutoOpenLogWindowOnLocalEvents',
					commit: 'logWindow/mutateAutoOpenLocalEvents',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::AutoOpenLogWindowOnGlobalEvents',
					commit: 'logWindow/mutateAutoOpenGlobalEvents',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::ClearLocalLogOnNewEvent',
					commit: 'logWindow/mutateClearLocalLogOnNewEvent',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::ClearGlobalLogOnNewEvent',
					commit: 'logWindow/mutateClearGlobalLogOnNewEvent',
					storage: 'local',
					compareTo: 'true'
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::DREAMConsole:LocalEvents',
					commit: 'logWindow/setLocalEvents',
					storage: 'session',
					toJson: true
				},
				{ root: true }
			)
			dispatch(
				'getFromStorage',
				{
					key: '::DREAMConsole:GlobalEvents',
					commit: 'logWindow/setGlobalEvents',
					storage: 'session',
					toJson: true
				},
				{ root: true }
			)
		},
		setCacheIsOpen({ commit, dispatch }, status) {
			commit('setIsOpen', status)
			if (!status) {
				dispatch('setCacheIsMinimized', false)
				dispatch('setBodyXPosition', 74)
				dispatch('setBodyYPosition', 310)
				dispatch('setBodyWidth', 350)
				dispatch('setBodyHeight', 210)
			}
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowState`, status)
		},
		setCacheIsMinimized({ commit }, status) {
			commit('setIsMinimize', status)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowMinimize`, status)
		},
		setAutoOpenLocalEvents({ commit }, status) {
			commit('mutateAutoOpenLocalEvents', status)
			localStorage.setItem(`${site.storageKeyPrefix}::AutoOpenLogWindowOnLocalEvents`, status)
		},
		setAutoOpenGlobalEvents({ commit }, status) {
			commit('mutateAutoOpenGlobalEvents', status)
			localStorage.setItem(`${site.storageKeyPrefix}::AutoOpenLogWindowOnGlobalEvents`, status)
		},
		setClearLocalLogOnNewEvent({ commit }, status) {
			commit('mutateClearLocalLogOnNewEvent', status)
			localStorage.setItem(`${site.storageKeyPrefix}::ClearLocalLogOnNewEvent`, status)
		},
		setClearGlobalLogOnNewEvent({ commit }, status) {
			commit('mutateClearGlobalLogOnNewEvent', status)
			localStorage.setItem(`${site.storageKeyPrefix}::ClearGlobalLogOnNewEvent`, status)
		},
		setBodyXPosition({ commit }, x) {
			commit('updateBodyXPosition', x)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowBodyXPosition`, x)
		},
		setBodyYPosition({ commit }, y) {
			commit('updateBodyYPosition', y)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowBodyYPosition`, y)
		},
		setBodyWidth({ commit }, width) {
			commit('updateBodyWidth', width)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowBodyWidth`, width)
		},
		setBodyHeight({ commit }, height) {
			commit('updateBodyHeight', height)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowBodyHeight`, height)
		},
		setFontSizeIndex({ commit }, index) {
			commit('updateFontSizeIndex', index)
			localStorage.setItem(`${site.storageKeyPrefix}::LogWindowFontSizeIndex`, index)
		},
		setLocalEventData({ state, commit, dispatch }, payload) {
			if (!Object.hasOwnProperty.call(state.localEvents, payload.key) && state.clearLocalLogOnNewEvent) {
				dispatch('clearCacheData', 'localEvents')
			}
			commit('addLocalEvent', payload)
			sessionStorage.setItem(`${site.storageKeyPrefix}::DREAMConsole:LocalEvents`, JSON.stringify(state.localEvents))
		},
		setGlobalEventData({ state, commit, dispatch }, payload) {
			if (!Object.hasOwnProperty.call(state.globalEvents, payload.key) && state.clearGlobalLogOnNewEvent) {
				dispatch('clearCacheData', 'globalEvents')
			}

			commit('addGlobalEvent', payload)
			sessionStorage.setItem(`${site.storageKeyPrefix}::DREAMConsole:GlobalEvents`, JSON.stringify(state.globalEvents))
		},
		clearCacheData({ commit }, property) {
			commit('clearData', property)
			if (property === 'localEvents') {
				sessionStorage.removeItem(`${site.storageKeyPrefix}::DREAMConsole:LocalEvents`)
			} else if (property === 'globalEvents') {
				sessionStorage.removeItem(`${site.storageKeyPrefix}::DREAMConsole:GlobalEvents`)
			}
		}
	}
}
