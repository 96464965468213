<template>
	<v-modal v-model="modalState" title="Connection Manager" class="mt-4 mb-3 px-4" no-header-border no-footer small>
		<div>
			<span class="font-weight-bold mr-3">Status:</span>
			<span class="connection-status-message shadow-1" :class="connected ? 'bg-green' : connecting ? 'bg-grey' : 'bg-dark-red'">
				<v-svg
					:icon="connected ? 'checkmark' : connecting ? 'sync-alt' : 'x-bold'"
					:size="connected ? 11 : 10"
					class="mr-1"
					offset
				/>
				{{ connectionText }}
			</span>
		</div>
		<div class="sub-script text-justify my-5">
			Connection manager lets you see the current status of your connection to the server. If you are connected, you will receive data
			in realtime without the need to refresh the page. If your connection is lost, you can still perform regular operations but will
			need to refresh the page to receive new data
		</div>
		<div class="mx-auto text-center">
			<v-btn
				:icon="connected ? 'disconnected' : connecting ? 'sync-alt' : 'wireless'"
				:info="!connected && !connecting"
				:error="connected"
				:disabled="connecting"
				small
				block
				@click="connectionAction"
			>
				{{ connected ? 'Disconnect' : connecting ? 'Connecting' : 'Connect' }}
			</v-btn>
		</div>
	</v-modal>
</template>

<script>
import VModal from '@/components/VModal'
import VSvg from '@/components/VSvg'
import VBtn from '@/components/VBtn'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ConnectionManager',
	components: { VBtn, VSvg, VModal },
	props: {
		modelValue: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:modelValue'],
	computed: {
		modalState: {
			get() {
				return this.modelValue
			},
			set() {
				this.$emit('update:modelValue', false)
			}
		},
		connectionText() {
			return this.connected && !this.connecting ? 'Connected' : this.connecting ? 'Connecting' : 'Disconnected'
		},
		...mapGetters({
			connected: 'socketHandler/connectedState',
			connecting: 'socketHandler/connectingState'
		})
	},
	methods: {
		connectionAction() {
			if (this.connected) {
				this.closeConnection()
			} else {
				this.startConnection()
			}
		},
		...mapActions({
			startConnection: 'socketHandler/startConnection',
			closeConnection: 'socketHandler/closeConnection'
		})
	}
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

.connection-window-menu {
	width: 400px;
}

.connection-status-message {
	color: $white;
	padding: 0.2rem 1.2rem 0.2rem 1rem;
	border-radius: $button-border-radius;
	font-size: 1.3rem;
}
</style>
