import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'

export default {
	namespaced: true,
	state: {
		data: [],
		storageKey: `${site.storageKeyPrefix}::EntryValidation`,
		endpoint: endpoints.getValidationRules
	},
	getters: {
		getData: state => state.data
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
		}
	},
	actions: {}
}
