import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import user from '@/plugins/user'

import { useGrid } from 'vue-screen'

const Home = () => import(/* webpackChunkName: "home-page" */ '@/views/MainHome.vue')
const Unauthorized = () => import(/* webpackChunkName: "unauthorized" */ '@/views/Unauthorized')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
const Maintenance = () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance')
const CreateRaffleEvent = () => import(/* webpackChunkName: "create-raffle-event" */ '@/views/RaffleEventCreator/CreateRaffleEvent')
const CloneRaffleEvent = () => import(/* webpackChunkName: "clone-raffle-event" */ '@/views/RaffleEventCreator/CloneRaffleEvent')
const AdminStoreView = () => import(/* webpackChunkName: "admin-store-view" */ '@/views/StoreView/AdminView')
const SettingsHome = () => import(/* webpackChunkName: "settings-home-page" */ '@/views/Settings/Home')
const RaffleEvent = () => import(/* webpackChunkName: "event-home-page" */ '@/views/RaffleEvents/EventsHome')
const RaffleEventDetails = () => import(/* webpackChunkName: "event-details" */ '@/views/RaffleEvents/Details/DetailsHome')
const RaffleEventEcomWinnerSelection = () =>
	import(/* webpackChunkName: "event-ecom-winner-selection" */ '@/views/RaffleEvents/Jobs/EcomWinnerSelection')
const RaffleEventEcomWinnerList = () => import(/* webpackChunkName: "event-ecom-winners" */ '@/views/RaffleEvents/Winners/EcomWinners')
const RaffleEventStoresWinnerSelection = () =>
	import(/* webpackChunkName: "event-store-winner-selection" */ '@/views/RaffleEvents/Jobs/StoreWinnerSelection')
const RaffleEventStoresWinnerList = () => import(/* webpackChunkName: "event-store-winners" */ '@/views/RaffleEvents/Winners/StoreWinners')
const RaffleEventEntries = () => import(/* webpackChunkName: "event-entries" */ '@/views/RaffleEvents/Entries/Home')
const RaffleEventEdit = () => import(/* webpackChunkName: "event-editor" */ '@/views/RaffleEvents/Edit/Home')
const RaffleEventCommonJobs = () => import(/* webpackChunkName: "event-common-jobs" */ '@/views/RaffleEvents/Jobs/Common')
const PrintStoreQueue = () => import(/* webpackChunkName: "print-sheet" */ '@/views/StoreView/Components/PrintSheet')
const Tools = () => import(/* webpackChunkName: "tools-home" */ '@/views/Tools/Home')

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Customer Raffle Management',
			homeButton: false,
			menuButton: true,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/Unauthorized',
		name: 'Unauthorized',
		component: Unauthorized,
		meta: {
			title: 'Customer Raffle Management',
			homeButton: true,
			menuButton: false
		}
	},
	{
		path: '/Maintenance',
		name: 'Maintenance',
		component: Maintenance,
		meta: {
			title: 'Maintenance Mode',
			homeButton: false,
			menuButton: false,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/CreateRaffleEvent',
		name: 'CreateRaffleEvent',
		component: CreateRaffleEvent,
		meta: {
			title: 'Create New Raffle Event',
			homeButton: true,
			menuButton: true,
			user: ['admin']
		}
	},
	{
		path: '/CloneRaffleEvent/:id',
		name: 'CloneRaffleEvent',
		component: CloneRaffleEvent,
		meta: {
			title: 'Clone Raffle Event',
			homeButton: true,
			menuButton: true,
			user: ['admin']
		}
	},
	{
		path: '/StoreQueue/:store',
		name: 'AdminStoreView',
		component: AdminStoreView,
		meta: {
			title: "Manage Store's Queue",
			homeButton: true,
			menuButton: true,
			user: ['admin', 'management']
		}
	},
	{
		path: '/Settings',
		name: 'SettingsHome',
		component: SettingsHome,
		meta: {
			title: 'Settings',
			homeButton: true,
			menuButton: true,
			user: ['admin']
		}
	},
	{
		path: '/ViewRaffleEvent/:id',
		name: 'RaffleEventParentLayout',
		component: RaffleEvent,
		meta: {
			title: 'Raffle Event Manager',
			homeButton: true,
			menuButton: true,
			user: ['admin', 'management']
		},
		children: [
			{
				path: '',
				name: 'RaffleEvent',
				component: RaffleEventDetails,
				meta: {
					user: ['admin', 'management'],
					disableScrollToAt: 'md'
				}
			},
			{
				path: 'EcomWinnerList',
				name: 'RaffleEventEcomWinnerList',
				component: RaffleEventEcomWinnerList,
				meta: {
					user: ['admin', 'management'],
					disableScrollToAt: 'md',
					submenu: 'winners'
				}
			},
			{
				path: 'StoresWinnerList',
				name: 'RaffleEventStoresWinnerList',
				component: RaffleEventStoresWinnerList,
				meta: {
					user: ['admin', 'management'],
					disableScrollToAt: 'md',
					submenu: 'winners'
				}
			},
			{
				path: 'EcomWinnerSelection',
				name: 'RaffleEventEcomWinnerSelection',
				component: RaffleEventEcomWinnerSelection,
				meta: {
					user: ['admin'],
					disableScrollToAt: 'md',
					submenu: 'jobs'
				}
			},
			{
				path: 'StoresWinnerSelection',
				name: 'RaffleEventStoresWinnerSelection',
				component: RaffleEventStoresWinnerSelection,
				meta: {
					user: ['admin'],
					disableScrollToAt: 'md',
					submenu: 'jobs'
				}
			},
			{
				path: 'Entries',
				name: 'RaffleEventEntries',
				component: RaffleEventEntries,
				meta: {
					user: ['admin', 'management'],
					disableScrollToAt: 'md'
				}
			},
			{
				path: 'Edit',
				name: 'RaffleEventEdit',
				component: RaffleEventEdit,
				meta: {
					user: ['admin'],
					disableScrollToAt: 'md'
				}
			},
			{
				path: 'CommonJobs',
				name: 'RaffleEventCommonJobs',
				component: RaffleEventCommonJobs,
				meta: {
					user: ['admin'],
					disableScrollToAt: 'md',
					submenu: 'jobs'
				}
			}
		]
	},
	{
		path: '/PrintStoreQueue',
		name: 'PrintStoreQueue',
		component: PrintStoreQueue,
		meta: {
			title: 'Print Store Queue',
			homeButton: true,
			menuButton: true,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/Tools',
		name: 'Tools',
		component: Tools,
		meta: {
			title: 'Tools',
			homeButton: true,
			menuButton: true,
			user: ['admin']
		}
	},
	{
		path: '/NotFound',
		name: 'NotFound',
		component: NotFound,
		meta: {
			title: 'Customer Raffle Management',
			homeButton: true,
			menuButton: true,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: NotFound,
		meta: {
			title: 'Customer Raffle Management',
			homeButton: true,
			menuButton: true,
			user: ['admin', 'management', 'store']
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to) {
		const grid = useGrid('bootstrap')

		if (!Object.hasOwnProperty.call(to.meta, 'disableScrollToAt')) {
			return { top: 0, behavior: 'smooth' }
		} else if (Object.hasOwnProperty.call(grid, to.meta.disableScrollToAt)) {
			if (grid[to.meta.disableScrollToAt]) {
				return { top: 0, behavior: 'smooth' }
			}
		}
	}
})

router.beforeEach(to => {
	if (to.meta.user && !user.either(to.meta.user)) {
		return '/Unauthorized'
	}

	if (store.state.maintenance && !store.getters['maintenanceAccess'] && to.fullPath !== '/Maintenance') {
		return '/Maintenance'
	} else if (store.state.maintenance) {
		store.commit('setPageTitle', 'MAINTENANCE MODE')
	} else {
		store.commit('setPageTitle', to.meta.title)
	}

	store.commit('setHomeButton', to.meta.homeButton)
	store.commit('setMenuButton', to.meta.menuButton)
})

export default router
