<template>
	<div>
		<div class="row mx-0 main-page-header-container">
			<div class="col px-0">
				<div>
					<transition name="fade" mode="out-in">
						<h1 class="main-page-header-title" :key="`pageTitle-${title}`">{{ title }}</h1>
					</transition>
				</div>
			</div>
			<transition name="fade" mode="out-in">
				<div
					class="col-auto px-0 text-right"
					v-if="showHomeButton && $user.either(['admin', 'management'])"
					:key="`pageHomeButton-${title}`"
				>
					<v-btn icon="home" to="/" :icon-only="!$grid.md" class="mt-2 mt-md-0">Home</v-btn>
				</div>
			</transition>
			<div class="col-auto px-0 text-right" v-if="showMenuButton && $user.either(['admin', 'management'])">
				<v-btn
					icon-only
					icon="hamburger-menu-thin"
					class="ml-4 mt-2 mt-md-0"
					@click="showOptionsWindowMenu = !showOptionsWindowMenu"
				/>

				<div
					class="menu-window bg-primary text-white shadow-4"
					v-if="showOptionsWindowMenu"
					v-click-outside="closeOptionsWindowMenu"
				>
					<div class="my-4 pl-4 menu-title disable-selection">MENU</div>
					<div class="py-4 pl-4 option-menu-items" @click="optionMenuHandler('newEvent')" v-if="$user.either(['admin'])">
						<v-svg icon="plus" size="15" offset class="mr-3" />
						Create New Raffle Event
					</div>
					<div
						class="py-4 pl-4 my-2 option-menu-items"
						@click="optionMenuHandler('connection')"
						v-if="$user.either(['admin', 'management'])"
					>
						<v-svg :icon="connectionButton" size="15" offset class="mr-3" />
						Connection Manager
					</div>
					<div class="py-4 pl-4 my-2 option-menu-items" @click="optionMenuHandler('console')" v-if="$user.either(['admin'])">
						<v-svg icon="command-window" size="15" offset class="mr-3" />
						DREAM Console
					</div>
					<div class="py-4 pl-4 mt-2 option-menu-items" @click="optionMenuHandler('tools')" v-if="$user.either(['admin'])">
						<v-svg icon="tools" size="15" offset class="mr-3" />
						Tools
					</div>
					<div class="py-4 pl-4 option-menu-items" @click="optionMenuHandler('settings')" v-if="$user.either(['admin'])">
						<v-svg icon="settings" size="15" offset class="mr-3" />
						Settings
					</div>
					<div class="py-4 pl-4 option-menu-items" @click="optionMenuHandler('fetchData')">
						<v-svg icon="refresh-bold" size="14.5" offset class="mr-3" />
						Pull Recent Changes
					</div>
				</div>
			</div>
		</div>
		<div class="mt-3 mb-5 text-center bg-dark-red p-4 h3 w-100 text-white" v-if="maintenanceMode && maintenanceAccess">
			<v-svg icon="warning-triangle" offset size="18" />
			<span class="px-4">MAINTENANCE MODE</span>
			<v-svg icon="warning-triangle" offset size="18" />
		</div>

		<connection-manager v-model="showConnectionMenu" />
		<dream-console-manager v-model="showLogWindowMenu" />
	</div>
</template>

<script>
import VBtn from '@/components/VBtn'
import VSvg from '@/components/VSvg'
import { mapGetters } from 'vuex'
import ConnectionManager from '@/views/PageHeader/ConnectionManager'
import DreamConsoleManager from '@/views/PageHeader/DREAMConsoleManager'

export default {
	name: 'PageHeader',
	components: { ConnectionManager, DreamConsoleManager, VSvg, VBtn },
	data() {
		return {
			showOptionsWindowMenu: false,
			showLogWindowMenu: false,
			showConnectionMenu: false,
			autoOpenConsole: false
		}
	},
	computed: {
		title() {
			return this.$store.state.pageTitle
		},
		showHomeButton() {
			return this.$store.state.homeButton
		},
		showMenuButton() {
			return this.$store.state.menuButton
		},
		connectionButton() {
			return this.connected && !this.connecting ? 'wireless' : this.connecting ? 'sync-alt' : 'disconnected'
		},
		...mapGetters({
			connected: 'socketHandler/connectedState',
			connecting: 'socketHandler/connectingState',
			maintenanceMode: 'maintenanceMode',
			maintenanceAccess: 'maintenanceAccess'
		})
	},
	methods: {
		optionMenuHandler(type) {
			switch (type) {
				case 'newEvent':
					this.$router.push('/CreateRaffleEvent')
					break
				case 'connection':
					this.showConnectionMenu = true
					break
				case 'console':
					this.showLogWindowMenu = true
					break
				case 'tools':
					this.$router.push('/Tools')
					break
				case 'settings':
					this.$router.push('/Settings')
					break
				case 'fetchData':
					this.$store.dispatch('taskRunner/runTask', 'fullUpdate')
					setTimeout(() => this.$message.success('You are up to date!'), 1500)
					break
			}

			this.closeOptionsWindowMenu()
		},
		closeOptionsWindowMenu() {
			this.showOptionsWindowMenu = false
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

.main-page-header-container {
	min-height: 80px;
	height: 80px;
	max-height: 80px;
	margin-top: -25px;

	.col,
	.col-auto {
		margin: auto 0;
	}
}

.menu-window {
	position: absolute;
	top: 45px;
	right: 0;
	z-index: 999;
	border-radius: $border-radius;
	text-align: left;
	width: 225px;
}

.menu-title {
	color: $darker-grey;
	font-weight: 300;
	letter-spacing: 0.1rem;
}

.option-menu-items {
	font-size: 1.2rem;
	cursor: pointer;

	&:hover {
		background-color: $secondary;

		&:last-child {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
}

@media only screen and (min-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.4rem;
		font-weight: 500;
	}
}

@media only screen and (max-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.2rem;
		font-weight: 500;
		margin-top: 1rem;
	}
}
</style>
