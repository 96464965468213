<template>
	<v-modal v-model="modalState" title="DREAM Console Manager" class="mt-2 mb-3 px-4" no-header-border no-footer small>
		<div class="mb-4 h4">
			<b>Local Events</b>
		</div>
		<div class="mb-4">
			<shapla-switch
				:model-value="autoOpenLocalEvents"
				label="Auto open console on local server responses"
				class="switch-label w-content"
				@input="setAutoOpenLocalEvents($event.target.checked)"
			/>
		</div>
		<div>
			<shapla-switch
				:model-value="clearLocalLogOnNewEvent"
				label="Auto clear local log on new server event"
				class="switch-label w-content"
				@input="setClearLocalLogOnNewEvent($event.target.checked)"
			/>
		</div>
		<div class="mt-5 h4">
			<b>Global Events</b>
		</div>
		<div class="my-4">
			<shapla-switch
				:model-value="autoOpenGlobalEvents"
				label="Auto open console on global server responses"
				class="switch-label w-content"
				@input="setAutoOpenGlobalEvents($event.target.checked)"
			/>
		</div>
		<div>
			<shapla-switch
				:model-value="clearGlobalLogOnNewEvent"
				label="Auto clear global log on new server event"
				class="switch-label w-content"
				@input="setClearGlobalLogOnNewEvent($event.target.checked)"
			/>
		</div>
		<div class="mx-auto text-center mt-5">
			<v-btn icon="maximize" small block @click="openLogWindowHandler(!opened)">
				{{ !opened ? 'Open Dream Console' : 'Close Dream Console' }}
			</v-btn>
		</div>
	</v-modal>
</template>

<script>
import VModal from '@/components/VModal'
import { mapActions, mapGetters } from 'vuex'
import ShaplaSwitch from '@shapla/vue-switch'
import VBtn from '@/components/VBtn'

export default {
	name: 'DreamConsoleManager',
	components: { VBtn, VModal, ShaplaSwitch },
	props: {
		modelValue: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:modelValue'],
	computed: {
		modalState: {
			get() {
				return this.modelValue
			},
			set() {
				this.$emit('update:modelValue', false)
			}
		},
		...mapGetters({
			opened: 'logWindow/getOpenState',
			autoOpenLocalEvents: 'logWindow/getAutoOpenLocalEvents',
			autoOpenGlobalEvents: 'logWindow/getAutoOpenGlobalEvents',
			clearLocalLogOnNewEvent: 'logWindow/getClearLocalLogOnNewEvent',
			clearGlobalLogOnNewEvent: 'logWindow/getClearGlobalLogOnNewEvent'
		})
	},
	methods: {
		openLogWindowHandler(action) {
			this.openLogWindow(action)
			this.$emit('update:modelValue', false)
		},
		...mapActions({
			openLogWindow: 'logWindow/setCacheIsOpen',
			setAutoOpenLocalEvents: 'logWindow/setAutoOpenLocalEvents',
			setAutoOpenGlobalEvents: 'logWindow/setAutoOpenGlobalEvents',
			setClearLocalLogOnNewEvent: 'logWindow/setClearLocalLogOnNewEvent',
			setClearGlobalLogOnNewEvent: 'logWindow/setClearGlobalLogOnNewEvent'
		})
	}
}
</script>

<style scoped>
.log-window-menu {
	width: 300px;
}

.switch-label > :deep(.shapla-switch__label) {
	font-size: 1.3rem;
}
</style>
