const mainDirectory = 'CustomerRaffleManagement'
const cfWorker = 'https://dream-portal.dtlr.workers.dev'

export default {
	host: '/',
	notificationHub: '/NotificationHub/Connection/WebSocket',
	getSettings: `${mainDirectory}/GetSettings`,
	updateSettings: `${mainDirectory}/UpdateSettings`,
	getRaffleEvents: `${mainDirectory}/GetRaffleEvents`,
	getWinners: `${mainDirectory}/GetWinners`,
	getStoreOverview: `${mainDirectory}/GetStoreOverview`,
	getStoreList: `${mainDirectory}/GetStoreList`,
	getProductFamily: `${mainDirectory}/getProductFamily`,
	getValidationRules: `${mainDirectory}/GetValidationRules`,
	createRaffleEvent: `${mainDirectory}/AddNewRaffleEvent`,
	editRaffleEvent: `${mainDirectory}/EditRaffleEvent`,
	checkForRaffleSubmissionUpdates: `${mainDirectory}/CheckForSubmissionUpdates`,
	pullRaffleSubmissions: `${mainDirectory}/SyncSubmissions`,
	notifyStoreWinners: `${mainDirectory}/NotifyStoreWinners`,
	markAsPickedUp: `${mainDirectory}/MarkAsPickedUp`,
	markQueueAsComplete: `${mainDirectory}/MarkStoreQueueEventAsComplete`,
	deleteRaffleEvent: `${mainDirectory}/DeleteRaffleEvent`,
	syncSubmissions: `${mainDirectory}/SyncSubmissions`,
	checkForSubmissionUpdates: `${mainDirectory}/CheckForSubmissionUpdates`,
	validateSubmissions: `${mainDirectory}/ValidateSubmissions`,
	ecomAvailableInventoryForWinnerSelection: `${mainDirectory}/EcomAvailableInventoryForWinnerSelection`,
	storeAvailableInventoryForWinnerSelection: `${mainDirectory}/StoreAvailableInventoryForWinnerSelection`,
	generateEcomRaffleWinners: `${mainDirectory}/GenerateEcomRaffleWinners`,
	generateStoreRaffleWinners: `${mainDirectory}/GenerateStoreRaffleWinners`,
	generateDraftOrders: `${mainDirectory}/GenerateDraftOrders`,
	validateDraftOrders: `${mainDirectory}/ValidateDraftOrders`,
	cancelDraftOrders: `${mainDirectory}/CancelOutstandingDraftOrders`,
	invoiceEcomWinners: `${mainDirectory}/InvoiceEcomWinners`,
	removeWinner: `${mainDirectory}/RemoveWinner`,
	storeViewGetRaffleEvents: `${mainDirectory}/StoreViewGetRaffleEvents`,
	storeViewGetWinners: `${mainDirectory}/StoreViewGetWinners`,
	storeViewGetOverview: `${mainDirectory}/StoreViewGetOverview`,
	storeDetailsTool: `${mainDirectory}/StoreDetailsTool`,
	createPageMetafield: `${mainDirectory}/CreatePageMetafieldTool`,
	taskRunner: `${mainDirectory}/TaskRunner`,
	setMaintenanceMode: `${mainDirectory}/SetMaintenanceMode`,
	getEligibleStoresByAllocation: `${mainDirectory}/GetEligibleStoresByAllocation`,
	resendStoreWinnerNotification: `${mainDirectory}/ResendStoreWinnerNotification`,
	getStoreName: `${mainDirectory}/GetStoreName`,
	upcomingEvents: `${mainDirectory}/GetUpcomingEvents`,
	getSizeQtyByStoreAllocation: `${mainDirectory}/GetSizeQtyByStoreAllocations`,
	getInventoryDetails: `${mainDirectory}/EventInventory`,
	getWorkerEvents: `${cfWorker}/CustomerRaffle/DreamEvents`,
	createPhase2RaffleEvent: `${cfWorker}/CustomerRaffle/CreateEvent`,
	deletePhase2RaffleEvent: `${cfWorker}/CustomerRaffle/DeleteEvent`,
	createJotFormEvent: `${cfWorker}/CustomerRaffle/CreateJotFormEvent`
}
