<template>
	<modal
		v-model="modalState"
		:options="{ transition: 200, styleModalContent: top ? { justifyContent: 'flex-start' } : {} }"
		:style="!modelValue ? 'overflow-y: hidden' : ''"
	>
		<div
			class="v-modal"
			:class="[
				!small && !large ? 'v-modal-medium' : small ? 'v-modal-small' : large ? 'v-modal-large' : '',
				{ 'bg-light-grey': bgGrey }
			]"
			ref="modalContent"
		>
			<div
				class="v-modal-header"
				:class="[
					title !== '' ? 'v-modal-header-w-title' : 'v-modal-header-wo-title',
					{ 'v-modal-header-w-border-bottom': !noHeaderBorder }
				]"
			>
				<h5 class="v-modal-title" v-if="title !== ''">{{ title }}</h5>
				<v-svg
					class="v-modal-close-icon"
					icon="x"
					alt="Close Modal Icon"
					size="12.5"
					black
					@clicked="emit('update:modelValue', false)"
				/>
			</div>
			<div :class="['v-modal-body', props.class]">
				<slot />
			</div>
			<div class="v-modal-footer" v-if="!noFooter">
				<div class="row mx-0">
					<div class="col" v-if="actionBtn !== ''">
						<v-btn
							block
							:white="whiteActionButton"
							:warning="warningActionButton"
							:error="errorActionButton"
							:info="infoActionButton"
							:success="successActionButton"
							:loading="loadingBtn"
							:loading-text="loadingBtnText"
							:soft-radius="softButtonRadius"
							@click="emit('actionBtn')"
						>
							{{ actionBtn }}
						</v-btn>
					</div>
					<div class="col" v-if="!noCloseBtn">
						<v-btn block :soft-radius="softButtonRadius" @click="emit('update:modelValue', false)">
							{{ closeBtnText }}
						</v-btn>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script setup>
import { computed, nextTick } from 'vue'
import VBtn from '@/components/VBtn'
import VSvg from '@/components/VSvg'
import { site } from '@/config/app.config'

const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false
	},
	title: {
		type: String,
		default: ''
	},
	noHeaderBorder: {
		type: Boolean,
		default: false
	},
	noFooter: {
		type: Boolean,
		default: false
	},
	noCloseBtn: {
		type: Boolean,
		default: false
	},
	actionBtn: {
		type: String,
		default: ''
	},
	whiteActionButton: {
		type: Boolean,
		default: false
	},
	successActionButton: {
		type: Boolean,
		default: false
	},
	warningActionButton: {
		type: Boolean,
		default: false
	},
	infoActionButton: {
		type: Boolean,
		default: false
	},
	errorActionButton: {
		type: Boolean,
		default: false
	},
	closeBtnText: {
		type: String,
		default: 'Close'
	},
	small: {
		type: Boolean,
		default: false
	},
	large: {
		type: Boolean,
		default: false
	},
	top: {
		type: Boolean,
		default: false
	},
	loadingBtn: {
		type: Boolean,
		default: false
	},
	loadingBtnText: {
		type: String,
		default: 'Loading...'
	},
	softButtonRadius: {
		type: Boolean,
		default: false
	},
	bgGrey: {
		type: Boolean,
		default: false
	},
	class: {
		type: String,
		default: ''
	}
})
const emit = defineEmits(['update:modelValue', 'actionBtn'])

const modalState = computed({
	get() {
		overflowControl()
		return props.modelValue
	},
	set() {
		emit('update:modelValue', false)
	}
})

const overflowControl = () => {
	nextTick(() => {
		const elementRef = `${site.modalContainer} .vue-universal-modal`
		const modalParent = document.querySelector(`${elementRef}:last-child`)
		const modalsOpened = document.querySelectorAll(elementRef).length

		if (props.modelValue) {
			modalParent.style.backgroundColor = '#0007'
		} else if (modalParent) {
			modalParent.style.removeProperty('background-color')
		}

		const navbar = document.querySelector('nav.navbar > div')
		const mainContent = document.querySelector('div#content')
		const footer = document.querySelector('footer.footer')

		if (props.modelValue && modalsOpened === 1) {
			const paddingRight = `${getBrowserScrollbarWidth()}px`
			navbar.style.paddingRight = paddingRight
			footer.style.paddingRight = paddingRight
			mainContent.style.paddingRight = paddingRight
			mainContent.style.transition = 'none'
			document.body.style.overflowY = 'hidden'
		} else if (!props.modelValue && modalsOpened === 1) {
			setTimeout(() => {
				document.body.style.removeProperty('overflow-y')
				navbar.style.removeProperty('padding-right')
				footer.style.removeProperty('padding-right')
				mainContent.style.removeProperty('padding-right')
				setTimeout(() => mainContent.style.removeProperty('transition'), 500)
			}, 300)
		}
	})
}

const getBrowserScrollbarWidth = () => {
	return window.innerWidth - document.body.clientWidth
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

.v-modal {
	background-color: $white;
	width: 100%;
	min-width: 450px;
	border-radius: $border-radius;
}

.v-modal-header {
	display: flex;
	padding: 1rem 1rem;
	margin-bottom: 0.5rem;
}

.v-modal-header-w-title {
	align-items: flex-start;
	justify-content: space-between;
}

.v-modal-header-w-border-bottom {
	border-bottom: 1px solid $light-grey;
}

.v-modal-header-wo-title {
	align-items: flex-end;
	justify-content: flex-end;
}

.v-modal-title {
	font-size: 2.35rem;
	margin-top: 0.5rem;
}

.v-modal-close-icon {
	align-self: center;
	cursor: pointer;
}

.v-modal-body {
	padding: 0.8rem 1.3rem;
}

.v-modal-footer {
	padding: 1.2rem 0.5rem;
	border-top: 1px solid $light-grey;
}

.v-modal-multi-button-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.v-modal-small {
	max-width: 500px;
}

.v-modal-medium {
	max-width: 800px;
}

.v-modal-large {
	max-width: 1050px;
}
</style>
