export default {
	__environment: 'prod',

	__setEnvironment(environment) {
		this.__environment = environment
	},

	is(environment) {
		return this.__environment === environment
	},

	not(environment) {
		return this.__environment !== environment
	},

	either(environment) {
		return Array.isArray(environment) ? environment.some(x => this.__environment === x) : this.__environment === environment
	},

	neither(environment) {
		return Array.isArray(environment) ? environment.some(x => this.__environment !== x) : this.__environment !== environment
	}
}
