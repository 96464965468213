import router from '@/router'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		runTask({ dispatch }, taskType) {
			if (taskType === 'refresh') {
				dispatch('refresh')
				return false
			}

			dispatch('init', { excludeSocket: true, execute: taskType }, { root: true })
		},
		refresh() {
			window.location.reload()
		},
		async toggleMaintenanceMode({ commit, rootGetters }, status) {
			const maintenanceMode = status === 'true'
			commit('setMaintenanceMode', maintenanceMode, { root: true })

			if (maintenanceMode && !rootGetters['maintenanceAccess']) {
				await router.push('/Maintenance')
			} else if (!maintenanceMode && !rootGetters['maintenanceAccess']) {
				await router.push('/')
			}
		},
		autoFullUpdateTask({ dispatch }) {
			setInterval(() => {
				dispatch('runTask', 'fullUpdate')
			}, 300000)
		}
	}
}
