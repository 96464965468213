import * as signalR from '@microsoft/signalr'
import endpoint from '@/config/api.endpoints'
import snackbar from '@/plugins/snackbar'
import socketListeners from '@/config/socketListeners'

let lockResolver

export default {
	namespaced: true,
	state: {
		connection: {},
		id: '',
		connected: false,
		connecting: false,
		manualDisconnection: false
	},
	getters: {
		connectedState: state => state.connected,
		connectingState: state => state.connecting,
		socketId: state => state.id
	},
	mutations: {
		setConnection(state, payload) {
			state.connection = payload
		},
		setId(state, id) {
			state.id = id
		},
		updateConnected(state, status) {
			state.connected = status
		},
		updateConnecting(state, status) {
			state.connecting = status
		},
		setManualDisconnection(state, status) {
			state.manualDisconnection = status
		}
	},
	actions: {
		async init({ dispatch }) {
			await dispatch('startConnection')
			dispatch('setupListeners')
			dispatch('reconnection')
		},
		setupListeners({ dispatch }) {
			for (const [key, value] of Object.entries(socketListeners)) {
				dispatch('eventHandler', { key, ...value })
			}

			//Custom Server Message
			dispatch('serverMessage')
		},
		eventHandler({ state, dispatch, rootGetters }, payload) {
			state.connection.on(payload.key, data => {
				const now = new Date().toLocaleString('en-us', {
					hour: '2-digit',
					minute: '2-digit'
				})

				const log = {
					property: 'localEvents',
					header: payload.header || 'Undefined Header',
					executionTime: now,
					by: data.by,
					key: data.key,
					data: {}
				}

				log.data[data.property] = { message: data.message, status: data.status }

				const logWindowState = rootGetters['logWindow/getOpenState']
				const logWindowMinimizedState = rootGetters['logWindow/getMinimizedState']
				const logWindowAutoOpenOnLocalEvents = rootGetters['logWindow/getAutoOpenLocalEvents']
				const logWindowAutoOpenOnGlobalEvents = rootGetters['logWindow/getAutoOpenGlobalEvents']

				if (data.global || payload.forceGlobal) {
					if (!logWindowState && logWindowAutoOpenOnGlobalEvents) {
						dispatch('logWindow/setCacheIsOpen', true, { root: true })
					} else if (logWindowMinimizedState && logWindowAutoOpenOnGlobalEvents) {
						dispatch('logWindow/setCacheIsMinimized', false, { root: true })
					}

					if (payload.module && payload.action) {
						dispatch(`${payload.module}/${payload.action}`, data.data || null, { root: true })
					}

					dispatch('logWindow/setGlobalEventData', log, { root: true })

					if (payload.callback && payload.callback instanceof Function) {
						payload.callback(data)
					}
				} else {
					if (!logWindowState && logWindowAutoOpenOnLocalEvents) {
						dispatch('logWindow/setCacheIsOpen', true, { root: true })
					} else if (logWindowMinimizedState && logWindowAutoOpenOnLocalEvents) {
						dispatch('logWindow/setCacheIsMinimized', false, { root: true })
					}

					dispatch('logWindow/setLocalEventData', log, { root: true })
				}
			})
		},
		serverMessage({ state }) {
			state.connection.on('messageToClient', data => {
				switch (data.status) {
					case 'done':
						snackbar.success(data.message, 10000)
						break
					case 'info':
						snackbar.info(data.message, 10000)
						break
					case 'error':
						snackbar.error(data.message, 10000)
						break
				}
			})
		},
		async buildConnection({ commit }) {
			const options = {
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets
			}

			commit('updateConnecting', true)
			const connection = await new signalR.HubConnectionBuilder()
				.withAutomaticReconnect()
				.withUrl(endpoint.notificationHub, options)
				.configureLogging(signalR.LogLevel.None)
				.build()
			commit('setConnection', connection)
		},
		async startConnection({ state, commit, dispatch }) {
			commit('updateConnecting', true)
			await dispatch('buildConnection')
			await state.connection
				.start()
				.then(() => {
					dispatch('establishConnectionHandler')
					commit('appReady', null, { root: true })
				})
				.catch(function (err) {
					snackbar.error(err, 12000)
				})
				.finally(() => commit('updateConnecting', false))
		},
		reconnection({ state, commit, dispatch }) {
			state.connection.onreconnecting(() => {
				commit('updateConnected', false)
				commit('updateConnecting', true)
				snackbar.warning(`Connection to websocket lost. Reconnecting...`)
			})

			state.connection.onreconnected(() => {
				dispatch('establishConnectionHandler')
				snackbar.success('Connected!')
			})

			state.connection.onclose(() => {
				if (!state.manualDisconnection) {
					commit('updateConnected', false)
					commit('updateConnecting', false)
					lockResolver()
					snackbar.error('Connection to websocket lost. Try restarting the connection or refreshing the page')
				}
			})
		},
		async closeConnection({ state, commit }) {
			commit('setManualDisconnection', true)
			await state.connection
				.stop()
				.then(() => {
					commit('updateConnected', false)
					commit('updateConnecting', false)
					commit('setManualDisconnection', false)
					lockResolver()
				})
				.catch(err => snackbar.error(`Failed to stop connection with error: ${err}`))
		},
		webLock() {
			if (navigator && navigator.locks && navigator.locks.request) {
				const p = new Promise(res => {
					lockResolver = res
				})

				navigator.locks.request('DREAM::CustomerRaffleManagement_WebLock', { mode: 'shared' }, () => p)
			}
		},
		getConnectionId({ state, commit }) {
			state.connection.invoke('GetConnectionId').then(id => {
				commit('setId', id)
			})
		},
		establishConnectionHandler({ commit, dispatch }) {
			dispatch('getConnectionId')
			dispatch('addToGroup')
			commit('updateConnected', true)
			commit('updateConnecting', false)
			dispatch('webLock')
		},
		addToGroup({ state, rootGetters }) {
			if (rootGetters['user/isAdmin']) {
				state.connection.invoke('AddToAdminGroup').then()
				state.connection.invoke('AddToOpsGroup').then()
			} else if (rootGetters['user/isManagement']) {
				state.connection.invoke('AddToManagementGroup').then()
				state.connection.invoke('AddToOpsGroup').then()
			} else if (rootGetters['user/isStore']) {
				state.connection.invoke('AddToStore', rootGetters['user/getStore']).then()
				state.connection.invoke('AddToAllStores').then()
			}

			state.connection.invoke('AddToAllGroups').then()
		}
	}
}
