import { site } from '@/config/app.config'
import endpoints from '@/config/api.endpoints'
import axios from 'axios'
import snackbar from '@/plugins/snackbar'
import crypto from '@/plugins/crypto'

export default {
	namespaced: true,
	state: {
		data: '',
		storageKey: `${site.storageKeyPrefix}::Settings`,
		endpoint: endpoints.getSettings
	},
	getters: {
		parsed: state => {
			try {
				return JSON.parse(crypto.httpDecrypt(state.data)).reduce((k, v) => ((k[[v.Id]] = v), k), {})
			} catch {
				return [{}]
			}
		},
		storeQueueShowPickupPinCode: (state, getters) => getters.parsed.ShowPinCode,
		storeQueueShowTransactionId: (state, getters) => getters.parsed.ShowTransactionId,
		storeQueueRequirePickupPinCode: (state, getters) => getters.parsed.RequirePinCode,
		storeQueueRequireTransactionId: (state, getters) => getters.parsed.RequireTransactionId,
		storeQueueStoresCanDisqualify: (state, getters) => getters.parsed.StoreCanDisqualifyEntry,
		storeQueueStoresCanAddToBlacklist: (state, getters) => getters.parsed.StoreCanAddToBlacklist,
		storeQueueStoresCanSwapWinners: (state, getters) => getters.parsed.StoreCanSwapWinners,
		storeQueueStoresCanRemoveWinners: (state, getters) => getters.parsed.StoresCanRemoveWinners,
		storeQueueStoresCanMarkAsComplete: (state, getters) => getters.parsed.StoresCanMarkAsComplete,
		storeQueueShowPDFButton: (state, getters) => getters.parsed.ShowGeneratePDFStoreQueueButton,
		storeQueueShowPrintButton: (state, getters) => getters.parsed.ShowPrintStoreQueueButton,
		storeQueueShowExportToExcelButton: (state, getters) => getters.parsed.ShowExportStoreQueueToExcelButton,
		customerStoreSelectionLimit: (state, getters) => getters.parsed.CustomerStoreSelectionLimit,
		adminMaintenanceAccess: (state, getters) => getters.parsed.AdminMaintenanceAccess,
		managementMaintenanceAccess: (state, getters) => getters.parsed.ManagementMaintenanceAccess,
		draftOrderAddressVerificationTag: (state, getters) => getters.parsed.EcomRaffleDraftOrderAddressVerificationTag,
		manualRaffleEntryId: (state, getters) => getters.parsed.ManualRaffleEntryId,
		manualRaffleEntryIdIntVal: (state, getters) => parseInt(getters.manualRaffleEntryId.DataValue),
		shopifyRaffleLandingPage: (state, getters) => getters.parsed.ShopifyRaffleLandingPage,
		mobileAppRaffleLandingPage: (state, getters) => getters.parsed.MobileAppRaffleLandingPage,
		raffleLandingPageMinimumEvents: (state, getters) => getters.parsed.RaffleLandingPageMinimumEvents,
		raffleLandingPageEventsByLastXDays: (state, getters) => getters.parsed.RaffleLandingPageEventsByLastXDays,
		listOfStoresThatCannotRemoveWinners: (state, getters) => {
			const data = getters.parsed.StoresThatCannotRemoveWinners

			if (!Array.isArray(data.DataValue)) {
				data.DataValue = JSON.parse(data.DataValue)
			}

			return data
		}
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
		}
	},
	actions: {
		async update({ rootGetters }, payload) {
			payload.SocketId = rootGetters['socketHandler/socketId']
			try {
				await axios
					.post(endpoints.updateSettings, payload)
					.then(res => snackbar.success(res.data))
					.catch(err => snackbar.error(err.response ? err.response.data : err.message, 6000))
			} catch (e) {
				snackbar.error(e, 6000)
			}
		},
		updateSettings({ commit, dispatch }, payload) {
			commit('setData', payload)
			dispatch('setCache', 'settings', { root: true })
		}
	}
}
