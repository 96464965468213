import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { site } from './config/app.config'

import api from '@/plugins/api'
import snackbar from '@/plugins/snackbar'
import envData from '@/plugins/env'
import user from '@/plugins/user'
import environment from '@/plugins/environment'

import 'vue-skeletor/dist/vue-skeletor.css'
import 'vue-universal-modal/dist/index.css'
import 'floating-vue/dist/style.css'
import VueScreen from 'vue-screen'
import { Skeletor } from 'vue-skeletor'
import VueUniversalModal from 'vue-universal-modal'
import vClickOutside from 'click-outside-vue3'
import VueClipboard from 'vue3-clipboard'
import VueLazyLoad from 'vue3-lazyload'
import { VTooltip, VClosePopper } from 'floating-vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueScreen, 'bootstrap')
app.use(VueUniversalModal, { teleportTarget: site.modalContainer })
app.use(vClickOutside)
app.use(VueLazyLoad)
app.use(VueClipboard, {
	autoSetContainer: true,
	appendToBody: true
})

app.config.globalProperties.$api = api
app.config.globalProperties.$message = snackbar
app.config.globalProperties.$env = envData
app.config.globalProperties.$user = user
app.config.globalProperties.$environment = environment

app.directive('tooltip', VTooltip)
app.directive('close-popper', VClosePopper)

app.component(Skeletor.name, Skeletor)

app.mount(site.appContainer)
