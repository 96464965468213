import { createStore } from 'vuex'
import user from '@/store/modules/user'
import settings from '@/store/modules/settings'
import raffleEvents from '@/store/modules/raffleEvents'
import winners from '@/store/modules/winners'
import storesOverview from '@/store/modules/storesOverview'
import stores from '@/store/modules/stores'
import entryValidation from '@/store/modules/entryValidation'
import logWindow from '@/store/modules/logWindow'
import socketHandler from '@/store/modules/socketHandler'
import taskRunner from '@/store/modules/taskRunner'
import dataHandler from '@/store/modules/dataHandler'
import { site } from '@/config/app.config'
import axios from 'axios'
import snackbar from '@/plugins/snackbar'

export default createStore({
	state: {
		pageTitle: 'Loading...',
		homeButton: false,
		menuButton: false,
		maintenance: false,
		phase2: false,
		ready: false,
		now: 0
	},
	getters: {
		maintenanceMode: state => state.maintenance,
		maintenanceAccess: (state, getters, rootState, rootGetters) => {
			return rootGetters['user/isAdmin']
				? rootGetters['settings/adminMaintenanceAccess'].BooleanValue
				: rootGetters['user/isManagement']
				? rootGetters['settings/managementMaintenanceAccess'].BooleanValue
				: false
		},
		phase2: state => state.phase2
	},
	mutations: {
		setPageTitle(state, title) {
			state.pageTitle = title
		},
		setHomeButton(state, status) {
			state.homeButton = status
		},
		setMenuButton(state, status) {
			state.menuButton = status
		},
		setMaintenanceMode(state, status) {
			state.maintenance = status
		},
		setPhase2(state, status) {
			state.phase2 = status
		},
		appReady(state) {
			state.ready = true
		},
		updateNowTimer(state) {
			state.now = new Date().getTime()
		}
	},
	actions: {
		async init({ dispatch, rootGetters }, payload) {
			payload ||= {}

			if (rootGetters['user/isAdmin'] || rootGetters['user/isManagement']) {
				if (!payload.execute || payload.execute === 'updateSettings') {
					dispatch('globalSettings')
				}

				if (!payload.excludeSocket) {
					await dispatch('socketHandler/init')
				}

				if (!payload.execute || payload.execute !== 'updateSettings') {
					dispatch('adminData', payload.execute || '')
				}
			} else if (rootGetters['user/isStore']) {
				if (!payload.excludeSocket) {
					dispatch('socketHandler/init')
				}

				if (!payload.execute || payload.execute === 'updateSettings') {
					dispatch('globalSettings')
				}

				if (!payload.execute || payload.execute !== 'updateSettings') {
					dispatch('storeViewData', payload.execute || '')
				}
			}
		},
		globalSettings({ dispatch }) {
			dispatch('dataHandler', { module: 'settings', raw: true })
		},
		adminData({ dispatch }, execute) {
			if (execute === '') {
				dispatch('logWindow/init')
			}

			if (execute === '' || execute === 'updateRaffleEvents' || execute === 'fullUpdate') {
				dispatch('dataHandler', { module: 'raffleEvents' })
			}

			if (execute === '' || execute === 'updateStoreOverview' || execute === 'fullUpdate') {
				dispatch('dataHandler', { module: 'storesOverview' })
			}

			if (execute === '' || execute === 'updateStoreList' || execute === 'fullUpdate') {
				dispatch('dataHandler', { module: 'stores' })
			}

			if (execute === '' || execute === 'updateEntryValidations' || execute === 'fullUpdate') {
				dispatch('dataHandler', { module: 'entryValidation' })
			}
		},
		storeViewData({ dispatch }, execute) {
			if (execute === '' || execute === 'updateEntryValidations' || execute === 'fullUpdate') {
				dispatch('dataHandler', { module: 'entryValidation' })
			}
		},
		async dataHandler({ state, commit, dispatch }, payload) {
			if (!payload.noCache) {
				const storedData = localStorage.getItem(state[payload.module].storageKey)
				const today = new Date()

				if (storedData !== null && storedData !== '') {
					const jsonData = JSON.parse(storedData)
					const storedDate = new Date(jsonData.date)
					const timeDiff = today.getTime() - storedDate.getTime()
					const dayDiff = timeDiff / (1000 * 3600 * 24)
					if (dayDiff < 1) {
						commit(`${payload.module}/setData`, jsonData.data)
					}
				}
			}

			const axiosRequest = payload.post
				? {
						method: 'post',
						url: state[payload.module].storeEndpoint,
						data: payload.post
				  }
				: {
						method: 'get',
						url: state[payload.module].endpoint
				  }

			await axios(axiosRequest)
				.then(res => {
					if (payload.raw) {
						commit(`${payload.module}/setData`, res.data)
					} else {
						commit(`${payload.module}/setData`, JSON.parse(res.data))
					}
					if (!payload.noCache) {
						dispatch('setCache', payload.module)
					}
				})
				.catch(error => {
					const errorObject = {}
					if (error.response) {
						errorObject.responseData = error.response.data || 'None'
						errorObject.responseStatus = error.response.status || 'None'
					}

					if (error.message) {
						errorObject.errorMessage = error.message
					}

					if (
						errorObject.responseData &&
						errorObject.responseStatus &&
						errorObject.errorMessage &&
						errorObject.responseData === 'None' &&
						errorObject.responseStatus === 'None' &&
						errorObject.errorMessage === 'Network Error'
					) {
						window.location.href = ''
					} else {
						snackbar.error(`Error loading ${state[payload.module].endpoint}`, 8000)
					}
				})
		},
		getFromStorage({ commit }, payload) {
			const getItem =
				payload.storage === 'local'
					? localStorage.getItem(site.storageKeyPrefix + payload.key)
					: sessionStorage.getItem(site.storageKeyPrefix + payload.key)
			if (getItem !== null && getItem !== '' && getItem !== 'undefined') {
				if (payload.toJson) {
					commit(payload.commit, JSON.parse(getItem))
				} else if (payload.compareTo) {
					commit(payload.commit, getItem === payload.compareTo)
				} else {
					commit(payload.commit, getItem)
				}
			}
		},
		updateNow({ commit }) {
			commit('updateNowTimer')
		},
		setCache({ state }, module) {
			const data = {
				date: new Date(),
				data: state[module].data
			}

			localStorage.setItem(state[module].storageKey, JSON.stringify(data))
		}
	},
	modules: {
		user,
		settings,
		raffleEvents,
		winners,
		storesOverview,
		stores,
		entryValidation,
		logWindow,
		socketHandler,
		taskRunner,
		dataHandler
	}
})
