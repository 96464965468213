import endpoint from '@/config/api.endpoints'
import config from '@/config/app.config'

export const env = {}

const envData = {
	...env,
	endpoint,
	config
}

export default envData
