export default {
	createRaffleEvent: {
		header: 'Create Raffle Event',
		module: 'raffleEvents',
		action: 'addEvent'
	},
	editRaffleEvent: {
		header: 'Edit Raffle Event',
		module: 'raffleEvents',
		action: 'editEvent'
	},
	removeRaffleEvent: {
		header: 'Remove Raffle Event',
		module: 'raffleEvents',
		action: 'removeEvent'
	},
	generateWinners: {
		header: 'New Winners',
		module: 'winners',
		action: 'pullWinners'
	},
	generateDraftOrders: {
		header: 'Ecom Draft Orders',
		module: 'winners',
		action: 'pullWinners'
	},
	validateDraftOrders: {
		header: 'Ecom Draft Orders validation',
		module: 'winners',
		action: 'pullWinners'
	},
	invoiceEcomWinners: {
		header: 'Invoice Ecom Winners',
		module: 'winners',
		action: 'pullWinners'
	},
	removeWinner: {
		header: 'Remove Winner',
		module: 'winners',
		action: 'removeWinner'
	},
	markStoreOrderAsPickedUp: {
		header: 'Mark Store Order As Picked Up',
		module: 'winners',
		action: 'markAsPickedUp'
	},
	markStoreQueueEventAsNotified: {
		header: 'Mark Store Queue Event As Notified',
		module: 'storesOverview',
		action: 'markAsNotified'
	},
	markStoreQueueEventAsComplete: {
		header: 'Mark Store Queue Event As Complete',
		module: 'storesOverview',
		action: 'markAsQueueComplete'
	},
	updateSettings: {
		header: 'Update App Settings',
		module: 'settings',
		action: 'updateSettings'
	},
	checkSubmissionUpdates: {
		header: 'Check Submission Updates'
	},
	syncSubmissions: {
		header: 'Sync Submissions',
		module: 'raffleEvents',
		action: 'updateSubmissions'
	},
	validateEntries: {
		header: 'Validation',
		module: 'raffleEvents',
		action: 'updateValidation'
	},
	taskRunner: {
		header: 'Task Runner',
		module: 'taskRunner',
		action: 'runTask',
		forceGlobal: true
	},
	maintenanceMode: {
		header: 'Maintenance Mode',
		module: 'taskRunner',
		action: 'toggleMaintenanceMode'
	}
}
